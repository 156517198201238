












import { Component, Vue } from 'vue-property-decorator';
import GeneralCrudList from '@/components/common/GeneralCrudList.vue';

@Component({
  components: { GeneralCrudList },
})
export default class Documents extends Vue {
  model: any = [{ name: 'description', label: 'Treść', type: 'textarea' }];
  listOptions: any = [
    { field: 'date', label: 'Data', nowrap: true },
    { field: 'description', label: 'Treść' },
  ];
}
